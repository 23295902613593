<template>
  <v-container class="mt-10 my-2 mx-2 px-2">
    <v-card class="pa-5 mt-3" shaped>
      <h3>{{ $t("gen.rank") }} :</h3>
      <div v-if="!dataGen.length">
        <div class="ma-2" v-for="g in 5" :key="g">
          <v-flex xs12 md10 sm10 justify-center>
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-flex>
        </div>
      </div>

      <div v-else>
        <v-data-table
          :items-per-page="50"
          :headers="headers"
          :items="dataGen"
          hide-default-footer
          class="elevation-1"
        >

         <template v-slot:item.ranking="{ item }">
            
            <v-chip color="maincolor2">
              {{ dataGen.indexOf(item) + 1}}
            </v-chip>
          </template>
          <template v-slot:item.user.username_u="{ item }">
            <div v-if="iduser == item.user.id" class="success">
              <span> {{ item.user.username_u }}</span> <strong> (You)</strong>
            </div>
            <div v-else>
              {{ item.user.username_u }}
            </div>
          </template>
          <template v-slot:item.allvictims="{ item }">
            <p style="color:red;font-weight:bold">{{item.allvictims}}</p>
          </template>
          
        </v-data-table>
      </div>
    </v-card>
    <v-card shaped class="ma-2 pa-2"> </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      iduser: this.$store.getters.getUserId,
      dataGen: [],
      lengs: [
        { title: "english", short: "en" },
        { title: "arabic", short: "ar" },
        { title: "thai", short: "th" },
        { title: "espane", short: "es" },
      ],
      headers: [
          {
          text: "",
          value: "ranking",
        },
        {
          text: this.$t("signup.form.username"),
          value: "user.username_u",
        },
        { text: this.$t("dash.allvictims"), value: "allvictims" },
        //   { text: this.$t("scama.time"), value: 'date' },
      ],
    };
  },
  methods: {
    base(string) {
      return btoa(string);
    },
  },
  mounted() {
    this.$http
      .get("/u/rank", {})
      .then((res) => {
        this.dataGen = res.data;
      })
      .catch(({ response }) => {
        this.$toastr("add", {
          title: "Opps",
          msg: response?.data?.error?.message,
          timeout: 3000,
          position: "toast-top-center",
          type: "warning",
          closeOnHover: true,
          clickClose: false,
        });
      });
  },
};
</script>